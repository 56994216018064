import { useApesStorageApi } from "@/api/useApesStorageApi";
import { Config } from "@/config";
import { FormulasIngredientType } from "@/helpers/formulas/formulas-ingredient-types";
import { isSatisfyIngredient } from "@/helpers/formulas/formulas-satisfy-ingredient";
import { formulasBuildFormulaIngredients } from "@/helpers/formulas/formulas-build-ingredients";
import { defineStore } from "pinia";
import { useMethBankStore } from "../meth-bank";
import { useWalletCollectionStore } from "../wallet-collection";

export const useFormulasStore = defineStore("formulas", {
  state: () => ({
    activeFormulaId: null,
    formulas: [],
    isLoading: false,
    // idx's from ingredients array - {}
    selectedTraits: {},
  }),
  getters: {
    isTrait() {
      return (type) => {
        return [
          FormulasIngredientType.TRAIT,
          FormulasIngredientType.TRAIT_CATEGORY,
          FormulasIngredientType.RARITY,
          FormulasIngredientType.FORMULA_TRAIT,
          FormulasIngredientType.TRAIT_ANY,
          FormulasIngredientType.TAGS,
        ].includes(type);
      };
    },
    isUtility() {
      return (type) => {
        return [FormulasIngredientType.METH_MACHINE].includes(type);
      };
    },
    isSelectable() {
      return (type) => {
        return [
          FormulasIngredientType.TRAIT_CATEGORY,
          FormulasIngredientType.RARITY,
          FormulasIngredientType.FORMULA_TRAIT,
          FormulasIngredientType.TRAIT_ANY,
          FormulasIngredientType.OG_BULL,
          FormulasIngredientType.TAGS,
          FormulasIngredientType.METH_MACHINE,
          FormulasIngredientType.APE,
          FormulasIngredientType.TEEN,
        ].includes(type);
      };
    },
    activeFormula() {
      return this.formulas.find(
        (formula) => formula.combination_id == this.activeFormulaId
      );
    },
    ingredientsSatisfyMap() {
      return this.formulaIngredients.map((ingredient, idx) => {
        return isSatisfyIngredient(ingredient, idx);
      });
    },

    formulaIngredients() {
      if (!this.activeFormula) return [];
      return formulasBuildFormulaIngredients(this.activeFormula);
    },
  },
  actions: {
    selectTrait(idx, trait) {
      this.selectedTraits = { ...this.selectedTraits, [idx]: trait };
    },
    selectFormula(id) {
      this.activeFormulaId = id;
      this.selectedTraits = {};
    },
    async fetchFormulas() {
      this.isLoading = true;
      const res = await useApesStorageApi({
        toast: { error: true },
      }).exec({
        url: "/forge/combinations",
        params: {
          network: Config.network.name,
          comingSoon: true,
        },
      });
      this.isLoading = false;
      if (!res) return;

      const mode = Config.mode;

      const formulas = [];

      res.forEach((formula) => {
        // if mode is production, then we need to remove all formulas that have only_staging set to true
        if (mode === "production" && formula.only_staging) {
          return;
        }

        if (!!formula.team_type) {
          formulas.push({ ...formula });
        } else {
          formulas.push(formula);
        }
      });

      this.formulas = formulas;

      // this.formulas = res.map((formula) => {
      //   // if mode is production, then we need to remove all formulas that have only_staging set to true
      //   // if (mode === "production" && formula.only_staging) {
      //   if (mode === "staging" && formula.only_staging) {
      //     return null;
      //   }

      //   if (!!formula.team_type) {
      //     return { ...formula };
      //   }
      //   return formula;
      // });
    },
  },
});
