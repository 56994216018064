import { useFormulasStore } from "@/store/formulas/formulas";
import { useMethBankStore } from "@/store/meth-bank";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import { FormulasIngredientType } from "./formulas-ingredient-types";

export const isSatisfyIngredient = (ingredient, idx) => {
  const formulasStore = useFormulasStore();
  const type = ingredient.type;
  if (type === FormulasIngredientType.METH) {
    return useMethBankStore().balance >= ingredient.item.value;
  }
  if (type === FormulasIngredientType.FORGE_BLOCK) {
    return (
      useWalletCollectionStore().forgeBlocksBalance.all >= ingredient.item.value
    );
  }
  if (type === FormulasIngredientType.TRAIT) {
    const walletTrait = useWalletCollectionStore().apeTraits.find(
      (trait) => trait.id == ingredient.item.id
    );
    if (!walletTrait) return false;

    return (
      walletTrait.offChain + walletTrait.balance >= (ingredient.item.count || 1)
    );
  }
  if (
    [
      FormulasIngredientType.TRAIT_CATEGORY,
      FormulasIngredientType.RARITY,
      FormulasIngredientType.FORMULA_TRAIT,
      FormulasIngredientType.TRAIT_ANY,
      FormulasIngredientType.OG_BULL,
      FormulasIngredientType.TAGS,
      FormulasIngredientType.METH_MACHINE,
      FormulasIngredientType.APE,
      FormulasIngredientType.TEEN,
    ].includes(type)
  ) {
    return !!formulasStore.selectedTraits[idx];
  }
  if (type === FormulasIngredientType.UTILITY) {
    const walletTrait = useWalletCollectionStore().utilities.find(
      (item) => item.id == ingredient.item.id
    );
    // add count check in future
    return !!walletTrait;
  }
  return false;
};
